<template>
  <div class="container pm-0 bg-e0">
    <div class="row my-0 py-0">
      <div class="col-sm-12">
        <div class="pt-2 pl-2">
          <UsersFilters @filters-changed="setFilters" />
        </div>
      </div>
    </div>

    <hr />

    <div class="row my-0 py-0">
      <div class="col-sm-6" v-if="superAdmin">
        <p class="pm-0 pl-2">
          <span class="pr-1">العدد:</span
          ><span class="link pr-1">{{ total }}</span
        ><span>{{ userAvailable }}</span>
        </p>
      </div>
      <div class="d-flex justify-content-end" :class="superAdmin ? 'col-sm-6' : 'col-12'" v-if="canCreateUser">
        <div class="pr-2" v-if="superAdmin">
          <form :action="printUsersLink" method="get" target="_blank">
            <button class="btn btn-sm btn-primary rounded-0" type="submit">
              <tooltip title="طباعة">
                <span class="far fa-file-excel"></span>
              </tooltip>
            </button>
            <input
              type="hidden"
              id="filters"
              name="filters"
              :value="printFilters"
            />
            <input type="hidden" id="type" name="type" :value="type" />
          </form>
        </div>
        <div class="pr-2">
          <router-link class="btn btn-info btn-sm rounded-0" :to="addRoute">
            <tooltip title="إضافة مستخدم">
              <i class="fa fa-plus"></i>
            </tooltip>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-sm-12">
        <div class="table-responsive pm-0">
          <table class="table table-bordered bg-light my-0">
            <tr class="bg-info text-light">
              <th>الإسم</th>
              <th>إسم المستخدم</th>
              <th>الهاتف</th>
              <th v-if="usersList">المحافظة</th>
              <th v-if="usersList">الحالة</th>
              <th v-if="usersList">الملف</th>
              <th>التاريخ</th>
              <th v-if="canUpdateUser">تعديل</th>
            </tr>
            <tr v-if="emptyList">
              <td
                :colspan="usersList ? 8 : 5"
                class="text-center font-weight-bolder"
              >
                لا يوجد
              </td>
            </tr>
            <UserRow
              v-else
              v-for="user in list"
              :user="user"
              :key="user.id"
              :users-list="usersList"
              @deleted="deleted"
            />
          </table>
        </div>
      </div>
    </div>
    <div class="row pm-0">
      <div class="col-sm-12 bg-e0" v-if="superAdmin">
        <ThePaginator
          url="/users"
          :total="total"
          :last-page="lastPage"
          :links="links"
          @link-changed="setLink"
          :show-per-page="superAdmin"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserRow from "@/components/Users/UserRow";
import ThePaginator from "@/components/UI/ThePaginator";
import pagination from "@/mixins/pagination";
import UsersFilters from "@/components/Users/UsersFilters";
import {mapGetters} from "vuex";
import UserRole from "@/extra/enums/UserRole";
import Tooltip from "@/components/UI/Tooltip";

export default {
  components: {Tooltip, UsersFilters, ThePaginator, UserRow},
  mixins: [pagination],
  data() {
    return {formData: {}};
  },
  watch: {
    type() {
      this.setType();
    },
  },
  computed: {
    ...mapGetters(["canUpdateUser", "canCreateUser", "superAdmin"]),
    usersList() {
      return this.$route.name === "users.list";
    },
    assistantsList() {
      return this.$route.name === "assistants.list";
    },
    defaultUrl() {
      return "/users";
    },
    addRoute() {
      return {
        name: this.assistantsList ? "assistants.create" : "users.create",
      };
    },
    type() {
      return this.assistantsList ? UserRole.Assistant : UserRole.Client;
    },
    userAvailable() {
      return this.assistantsList ? "مساعد" : "مستخدم";
    },
    printUsersLink() {
      return `${this.$store.getters.publicPath}/manual-users-excel`;
    },
    printFilters() {
      try {
        return JSON.stringify(this.filters);
      } catch {
        return this.filters;
      }
    }
  },
  methods: {
    async setType() {
      this.formData.type = this.type;
      if (this.superAdmin)
        await this.setList();
    },
  },
  async created() {
    await this.setType();
  },
};
</script>

<style scoped>
.table th,
.table td {
  min-width: 100px !important;
}
</style>
